import { SITE_TITLE, SITE_DESCRIPTION } from '../constants';

export const meta = () => ({
  title: SITE_TITLE,
  description: `${SITE_TITLE} | ${SITE_DESCRIPTION}`,
});

export default function Index() {
  return (
    <div className="page">
      <main />
    </div>
  );
}
